<template>
  <v-row>
    <v-col>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(date)"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          no-title
          v-model="computedDateFormatted"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-text-field v-model="time" type="time"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TimeSelector",
  props: {
    value: Date,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    computedDateFormatted: {
      get() {
        const [day, month, year] = this.date
          .toLocaleDateString("fr-FR", {
            dateStyle: "short",
          })
          .split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      set(val) {
        this.date = new Date(val + "T00:00:00");
      },
    },
    date: {
      get() {
        return new Date(this.value.toDateString());
      },
      set(val) {
        this.$emit("input", new Date(val.getTime() + this.timeSecs(this.time)));
      },
    },
    time: {
      get() {
        return (
          this.value.getHours().toString().padStart(2, "0") +
          ":" +
          this.value.getMinutes().toString().padStart(2, "0")
        );
      },
      set(val) {
        this.$emit("input", new Date(this.date.getTime() + this.timeSecs(val)));
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return date.toLocaleDateString("fr-FR", { dateStyle: "short" });
    },
    timeSecs(val) {
      const [hours, minutes] = val.split(":");
      return (hours * 3600 + minutes * 60) * 1000;
    },
  },
};
</script>

<style>
</style>