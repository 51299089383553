<template>
  <v-card flat>
    <template v-if="vote">
      <v-card-title>{{ vote.title }}</v-card-title>
      <v-card-subtitle>
        <p>
          {{ vote.description }}
        </p>
        <p class="mb-0 pb-0 text-subtitle-1">
          Ouvert de <span class="black--text">{{ formatDate(vote.begin) }}</span> à <span class="black--text">{{ formatDate(vote.end) }}</span>
          <br/>
          <span v-if="vote.voters && vote.voters.length > 0">
            {{vote.voters.length}} étudiants peuvent voter.
          </span>
          <span v-else>
            Tous les étudiants peuvent voter.
          </span>
        </p>
      </v-card-subtitle>
      <v-card-text>
        <v-btn outlined @click="settings = true"> Réglages </v-btn>
        <v-row justify="center">
          <v-col cols="11">
            <v-card
              outlined
              v-for="(q, i) in vote.questions"
              :key="q._id"
              class="pa-2 mt-4"
            >
              <v-card-title>{{ i + 1 }} - {{ q.label }}</v-card-title>
              <v-card-subtitle v-if="q.description">{{
                q.description
              }}</v-card-subtitle>
              <v-card-text class="text-center">
                <VueApexCharts
                  type="pie"
                  width="380"
                  :series="series(q._id)"
                  :options="chartOptions(q)"
                >
                </VueApexCharts>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <v-dialog v-model="loading" dense persistent width="67">
      <v-card class="pa-4 text-center">
        <v-progress-circular indeterminate class="pa-3"></v-progress-circular>
      </v-card>
    </v-dialog>
    <v-dialog v-if="vote" v-model="settings" dense persistent>
      <v-card>
        <v-card-title>Réglages</v-card-title>
        <v-card-text>
          <p>
            <span class="text-subtitle-1 black--text"
              >Date d'ouverture du sondage</span
            >
            <TimeSelector v-model="vote.begin"></TimeSelector>
            {{ vote.begin }}
          </p>
          <p>
            <span class="text-subtitle-1 black--text"
              >Date de fermeture du sondage</span
            >
            <TimeSelector v-model="vote.end"></TimeSelector>
            {{ vote.end }}
          </p>
          <v-btn color="green" @click="saveSettings">Sauvegarder</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import TimeSelector from "@/components/TimeSelector";

export default {
  name: "Manage",
  components: { VueApexCharts, TimeSelector },
  data() {
    return {
      vote: undefined,
      // Control
      loading: true,
      settings: false,
    };
  },
  mounted() {
    this.axiosPost(process.env.VUE_APP_API_URL + "/api/getResults", {
      id: this.id,
    })
      .then((resp) => {
        let vote = resp.data;
        vote.end = new Date(vote.end || Date.now() + 3600 * 2 * 1000);
        vote.begin = new Date(vote.begin || Date.now() + 3600 * 2 * 1000);
        this.vote = vote;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    chartOptions(q) {
      let answers = [...q.answers];
      answers.sort((a, b) => {
        a._id - b._id;
      });
      return {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: answers.map((x) => x.label),
      };
    },
    series(id) {
      for (let rq of this.vote.results) {
        if (rq.id == id) {
          let votes = [...rq.votes];
          votes.sort((a, b) => {
            a.id - b.id;
          });
          return votes.map((x) => x.count);
        }
      }
      return [];
    },
    saveSettings() {
      this.axiosPost(process.env.VUE_APP_API_URL + "/api/saveSettings", {
        id: this.id,
        settings: {
          end: this.vote.end.getTime(),
          begin: this.vote.begin.getTime(),
        },
      })
        .then(() => {
          this.settings = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("fr", {
        dateStyle: "short",
        timeStyle: "medium",
      }).format(new Date(date));
    },
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
};
</script>